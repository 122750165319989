const arrayComparison = (arr1, arr2) => {
  // if (arr1.length != arr2.length) return false

  // console.log('arr1', arr1)
  // console.log('arr2', arr2)

  // for(var i = 0; i < arr1.length; i++) {
  //   if (arr1[i] !== arr2[i]) return false
  // }

  if (arr1 !== arr2) return false
  return true
}

export default arrayComparison
