<template>
  <section class="section-profile sectionTelegram">
    <h2 class="section-profile__title">
      Добавление групп Telegram
    </h2>
    <form class="section-profile__form">
      <legend class="section-profile__legend sectionTelegram__mobile-info">
        <h3>Telegram группы</h3>
        <p>
          Здесь вы можете добавить телеграм группы <br>
          на которые должны приходить сообщения. <br>
          Для этого необходимо: <br>
          1. Добавить в вашу телеграм-группу бота @getmyid_bot <br>
          После добавления, бот автоматически отправит <br>
          сообщение с вашим Current chat ID. <br>
          2. Вставить в вашей админке Tapper в поле "Название" Current <br>
          chat ID: чата из <br>
          сообщения бота вместе с минусом (если есть). <br>
          (Например: -123456789) <br>
          3. Нажать на кнопку "Сохранить" <br>
          4. После этого необходимо добавить <br>
          бота @TapperCloudGroupBot в вашу телеграм-группу. <br><br>
          Также вы можете настроить какие уведомления <br>
          должны приходить в группу, для этого <br>
          необходимо нажать на шестеренку возле <br>
          добавленной группы, а затем выключить или <br>
          включить уведомления.
        </p>
      </legend>
      <fieldset class="section-profile__group">
        <ul class="telegram__list">
          <li
            v-for="(item, index) in telegramList"
            :key="item.id"
            class="telegram__item"
          >
            <vInput
              :id="item.telegram_id.toString()"
              v-model="item.telegram_id"
              :value="item.telegram_id"
              :placeholder="'Группа №' + (index + 1)"
              rule="telegramId"
            />
            <div
              class="telegram__settings"
              @click="toggleSettingsModal({ ...item, index: index + 1 })"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_8929_60454)">
                  <path
                    d="M13.909 10.0916C14.9634 11.146 14.9634 12.8556 13.909 13.91C12.8546 14.9644 11.145 14.9644 10.0906 13.91C9.0362 12.8556 9.0362 11.146 10.0906 10.0916C11.145 9.03718 12.8546 9.03718 13.909 10.0916"
                    stroke="#80869A"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.24987 12C5.24987 12.297 5.27687 12.594 5.31287 12.882L3.72487 14.124C3.37287 14.4 3.27687 14.893 3.50087 15.28L4.91287 17.723C5.13587 18.11 5.60987 18.273 6.02487 18.107L7.44687 17.536C7.72787 17.423 8.04087 17.468 8.29287 17.635C8.51287 17.781 8.74087 17.915 8.97687 18.035C9.24687 18.172 9.44287 18.417 9.48587 18.717L9.70287 20.23C9.76587 20.672 10.1449 21 10.5909 21H13.4079C13.8539 21 14.2329 20.672 14.2959 20.23L14.5129 18.718C14.5559 18.418 14.7539 18.171 15.0249 18.035C15.2599 17.917 15.4869 17.784 15.7059 17.639C15.9599 17.471 16.2739 17.423 16.5559 17.537L17.9749 18.107C18.3889 18.273 18.8629 18.11 19.0869 17.723L20.4989 15.28C20.7229 14.893 20.6269 14.399 20.2749 14.124L18.6869 12.882C18.7229 12.594 18.7499 12.297 18.7499 12C18.7499 11.703 18.7229 11.406 18.6869 11.118L20.2749 9.876C20.6269 9.6 20.7229 9.107 20.4989 8.72L19.0869 6.277C18.8639 5.89 18.3899 5.727 17.9749 5.893L16.5559 6.463C16.2739 6.576 15.9599 6.529 15.7059 6.361C15.4869 6.216 15.2599 6.083 15.0249 5.965C14.7539 5.829 14.5559 5.582 14.5129 5.282L14.2969 3.77C14.2339 3.328 13.8549 3 13.4089 3H10.5919C10.1459 3 9.76687 3.328 9.70387 3.77L9.48587 5.284C9.44287 5.583 9.24587 5.829 8.97687 5.966C8.74087 6.086 8.51287 6.221 8.29287 6.366C8.03987 6.532 7.72687 6.577 7.44587 6.464L6.02487 5.893C5.60987 5.727 5.13587 5.89 4.91287 6.277L3.50087 8.72C3.27687 9.107 3.37287 9.601 3.72487 9.876L5.31287 11.118C5.27687 11.406 5.24987 11.703 5.24987 12V12Z"
                    stroke="#80869A"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_8929_60454">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div
              class="telegram__del"
              @click="toggleDelModal({ ...item, index: index + 1 })"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_8962_59965)">
                  <g clip-path="url(#clip1_8962_59965)">
                    <rect
                      width="24"
                      height="24"
                      rx="12"
                      fill="#F7F8FA"
                    />
                    <path
                      d="M16 8L8 16M8 8L16 16L8 8Z"
                      stroke="#EC4E4E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <rect
                    x="0.5"
                    y="0.5"
                    width="23"
                    height="23"
                    rx="11.5"
                    stroke="#EDEEF1"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_8962_59965">
                    <rect
                      width="24"
                      height="24"
                      rx="12"
                      fill="white"
                    />
                  </clipPath>
                  <clipPath id="clip1_8962_59965">
                    <rect
                      width="24"
                      height="24"
                      rx="12"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </li>
        </ul>
        <div
          v-if="telegramList.length < 5"
          class="addButton"
          @click="addTelegramField"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 8V16V8ZM16 12H8H16ZM12 21C7.029 21 3 16.971 3 12C3 7.029 7.029 3 12 3C16.971 3 21 7.029 21 12C21 16.971 16.971 21 12 21Z"
              stroke="url(#paint0_linear_8916_52153)"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <defs>
              <linearGradient
                id="paint0_linear_8916_52153"
                x1="21"
                y1="3"
                x2="3.44661"
                y2="21.4255"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#547AFF" />
                <stop
                  offset="1"
                  stop-color="#413DFF"
                />
              </linearGradient>
            </defs>
          </svg>
          Добавить группу
        </div>
        <vButton
          :disabled="!changeData || errors"
          @click="save"
        >
          Сохранить
        </vButton>
        <legend class="section-profile__legend">
          <h3>Telegram группы</h3>
          <p>
            Здесь вы можете добавить телеграм группы <br>
            на которые должны приходить сообщения. <br>
            Для этого необходимо: <br>
            1. Добавить в вашу телеграм-группу бота @getmyid_bot <br>
            После добавления, бот автоматически отправит <br>
            сообщение с вашим Current chat ID. <br>
            2. Вставить в вашей админке Tapper в поле "Название" Current <br>
            chat ID: чата из <br>
            сообщения бота вместе с минусом (если есть). <br>
            (Например: -123456789) <br>
            3. Нажать на кнопку "Сохранить" <br>
            4. После этого необходимо добавить <br>
            бота @TapperCloudGroupBot в вашу телеграм-группу. <br><br>
            Также вы можете настроить какие уведомления должны <br>
            приходить в группу, для этого необходимо нажать на <br>
            шестеренку возле добавленной группы, а затем выключить <br>
            или включить уведомления.
          </p>
        </legend>
      </fieldset>
    </form>

    <!-- Модалка с настройками -->
    <div
      v-if="modal"
      class="sectionTelegram-modal"
    >
      <div
        class="sectionTelegram-modal__overlay"
        @click="toggleSettingsModal(null)"
      />
      <div class="sectionTelegram-modal__content">
        <div
          class="sectionTelegram-modal__close"
          @click="toggleSettingsModal(null)"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_8962_59356)">
              <g clip-path="url(#clip1_8962_59356)">
                <rect
                  width="24"
                  height="24"
                  rx="12"
                  fill="#F7F8FA"
                />
                <path
                  d="M16 8L8 16M8 8L16 16L8 8Z"
                  stroke="#EC4E4E"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <rect
                x="0.5"
                y="0.5"
                width="23"
                height="23"
                rx="11.5"
                stroke="#EDEEF1"
              />
            </g>
            <defs>
              <clipPath id="clip0_8962_59356">
                <rect
                  width="24"
                  height="24"
                  rx="12"
                  fill="white"
                />
              </clipPath>
              <clipPath id="clip1_8962_59356">
                <rect
                  width="24"
                  height="24"
                  rx="12"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="sectionTelegram-modal__title">
          Настройка уведомлений для группы №{{ selectedField.index }}
        </div>
        <div class="sectionTelegram-modal__subtitle">
          Включить уведомления:
        </div>
        <ul class="sectionTelegram-modal__checkBoxList">
          <li
            class="sectionTelegram-modal__checkboxItem"
            :class="{
              active:
                selectedField.notifications.waiter_call &&
                selectedField.notifications.payment &&
                selectedField.notifications.rating,
            }"
            @click="changeNotifications('all')"
          >
            <div class="sectionTelegram-modal__checkbox" />
            Все
          </li>
          <li
            class="sectionTelegram-modal__checkboxItem"
            :class="{
              active: selectedField.notifications.waiter_call,
            }"
            @click="changeNotifications('waiter_call')"
          >
            <div class="sectionTelegram-modal__checkbox" />
            Сообщения о вызове официанта
          </li>
          <li
            class="sectionTelegram-modal__checkboxItem"
            :class="{
              active: selectedField.notifications.rating,
            }"
            @click="changeNotifications('rating')"
          >
            <div class="sectionTelegram-modal__checkbox" />
            Сообщения о рейтинге и отзывах
          </li>
          <li
            class="sectionTelegram-modal__checkboxItem"
            :class="{
              active: selectedField.notifications.payment,
            }"
            @click="changeNotifications('payment')"
          >
            <div class="sectionTelegram-modal__checkbox" />
            Сообщения об оплатах
          </li>
        </ul>
        <div class="sectionTelegram-modal__buttons">
          <vButton
            theme="gray"
            @click="toggleSettingsModal(null)"
          >
            Отменить
          </vButton>
          <vButton @click="saveNotifications">
            Применить
          </vButton>
        </div>
      </div>
    </div>

    <!-- Модалка удаления -->
    <div
      v-if="modalDel"
      class="sectionTelegram-modal"
    >
      <div
        class="sectionTelegram-modal__overlay"
        @click="toggleDelModal(null)"
      />
      <div class="sectionTelegram-modal__content">
        <div class="sectionTelegram-modal__title">
          Предупреждение
        </div>
        <div
          class="sectionTelegram-modal__close"
          @click="toggleDelModal(null)"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_8962_59356)">
              <g clip-path="url(#clip1_8962_59356)">
                <rect
                  width="24"
                  height="24"
                  rx="12"
                  fill="#F7F8FA"
                />
                <path
                  d="M16 8L8 16M8 8L16 16L8 8Z"
                  stroke="#EC4E4E"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <rect
                x="0.5"
                y="0.5"
                width="23"
                height="23"
                rx="11.5"
                stroke="#EDEEF1"
              />
            </g>
            <defs>
              <clipPath id="clip0_8962_59356">
                <rect
                  width="24"
                  height="24"
                  rx="12"
                  fill="white"
                />
              </clipPath>
              <clipPath id="clip1_8962_59356">
                <rect
                  width="24"
                  height="24"
                  rx="12"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="sectionTelegram-modal__description">
          Вы действительно хотите отвязать группу №{{ selectedField.index }} ?
        </div>
        <div class="sectionTelegram-modal__buttons">
          <vButton @click="delTelegramField(selectedField.id)">
            Отвязать
          </vButton>
          <vButton
            theme="gray"
            @click="toggleDelModal(null)"
          >
            Отменить
          </vButton>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vButton from '@/components/v-button.vue'
import vInput from '@/components/v-input'
import arrayComparison from '@/utils/arrayComparison'
export default {
  name: 'VSectionTelegram',
  components: {
    vButton,
    vInput,
  },
  data() {
    return {
      telegramList: [],
      changeData: false,
      selectedField: null,
      modal: false,
      modalDel: false,
      oldTelegramList: [],
      errors: false,
    }
  },

  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED']),
    ...mapGetters('telegram', ['GET_GROUPS']),
  },

  watch: {
    telegramList: {
      deep: true,
      handler() {
        const emptyField = this.telegramList.some(
          (i) => i.telegram_id.length == 0
        )

        const oldArrStr = JSON.stringify(this.oldTelegramList)
        const newArrStr = JSON.stringify(this.telegramList)

        const res = arrayComparison(oldArrStr, newArrStr)

        if (!emptyField && !res) {
          this.changeData = true
        } else {
          this.changeData = false
        }
        setTimeout(() => {
          const errors = document.querySelectorAll('.vLandingInput.err')
          this.errors = errors.length > 0
        }, 100)
      },
    },
  },

  async mounted() {
    await this.FETCH_GROUPS(this.GET_AUTHENTIFICATED)
    let copyArr = JSON.stringify(this.GET_GROUPS)
    copyArr = JSON.parse(copyArr)
    this.telegramList = this.GET_GROUPS
    this.oldTelegramList = copyArr
    setTimeout(() => {
      this.changeData = false
      this.TOGGLE_PRELOADER(false)
    }, 500)
  },

  methods: {
    ...mapActions('preloader', ['TOGGLE_PRELOADER']),
    ...mapActions('telegram', [
      'FETCH_GROUPS',
      'DELETE_GROUP',
      'CREATE_GROUP',
      'UPDATE_GROUP',
      'NOTIFICATIONS',
    ]),
    changeNotifications(type) {
      if (type === 'all') {
        if (
          this.selectedField.notifications.waiter_call === 1 &&
          this.selectedField.notifications.rating === 1 &&
          this.selectedField.notifications.payment === 1
        ) {
          this.selectedField.notifications.waiter_call = 0
          this.selectedField.notifications.rating = 0
          this.selectedField.notifications.payment = 0
        } else {
          this.selectedField.notifications.waiter_call = 1
          this.selectedField.notifications.rating = 1
          this.selectedField.notifications.payment = 1
        }
      } else {
        this.selectedField.notifications[type] =
          this.selectedField.notifications[type] === 1 ? 0 : 1
      }
    },
    saveNotifications() {
      this.telegramList.forEach((i) => {
        if (i.id === this.selectedField.id) {
          i.notifications = this.selectedField.notifications
        }
      })
      this.modal = !this.modal
    },
    async save() {
      this.TOGGLE_PRELOADER(true)

      for (const telegramItem of this.telegramList) {
        let id = telegramItem.id
        for (const oldTelegramItem of this.oldTelegramList) {
          if (telegramItem.id === oldTelegramItem.id) {
            id = null
          }
        }
        if (id !== null) {
          // Создаем новые группы
          const newId = await this.CREATE_GROUP({
            telegramId: telegramItem.telegram_id,
            token: this.GET_AUTHENTIFICATED,
          })
          telegramItem.id = newId
          id = newId
        } else {
          // Обновление группы
          await this.UPDATE_GROUP({
            telegramId: telegramItem.telegram_id,
            id: telegramItem.id,
            token: this.GET_AUTHENTIFICATED,
          })
        }
      }

      // Обновление оповещений
      for (const telegramItem of this.telegramList) {
        await this.NOTIFICATIONS({
          id: telegramItem.id,
          token: this.GET_AUTHENTIFICATED,
          notifications: telegramItem.notifications,
        })
      }

      // Удаление группы
      for (const oldTelegramItem of this.oldTelegramList) {
        let id = oldTelegramItem.id
        for (const telegramItem of this.telegramList) {
          if (oldTelegramItem.id === telegramItem.id) {
            id = null
          }
        }
        if (id) {
          await this.DELETE_GROUP({
            id,
            token: this.GET_AUTHENTIFICATED,
          })
        }
      }

      await this.FETCH_GROUPS(this.GET_AUTHENTIFICATED)
      let copyArr = JSON.stringify(this.GET_GROUPS)
      copyArr = JSON.parse(copyArr)
      this.telegramList = this.GET_GROUPS
      this.oldTelegramList = copyArr

      this.changeData = false
      this.TOGGLE_PRELOADER(false)
    },
    addTelegramField() {
      if (!this.telegramList.length) {
        this.telegramList.push({
          id: 0,
          name: '',
          notifications: {
            payment: 1,
            rating: 1,
            waiter_call: 1,
          },
          telegram_id: '',
          type: 'group',
          users_id: 0,
        })
      } else {
        this.telegramList.push({
          id: this.telegramList[this.telegramList.length - 1].id + 1,
          name: '',
          notifications: {
            payment: 1,
            rating: 1,
            waiter_call: 1,
          },
          telegram_id: '',
          type: 'group',
          users_id: 0,
        })
      }
    },
    delTelegramField(id) {
      const oldState = this.changeData

      const copyTelegramList = this.telegramList.filter((i) => i.id !== id)
      this.telegramList = copyTelegramList

      if (!oldState) {
        this.changeData = false
      }
      this.modalDel = false
    },
    toggleDelModal(item) {
      if (!item) {
        this.modalDel = !this.modalDel
        return
      }
      const res = this.oldTelegramList.some((i) => i.id === item.id)
      if (res) {
        this.selectedField = item
        this.modalDel = !this.modalDel
      } else {
        this.delTelegramField(item.id)
      }
    },
    toggleSettingsModal(item) {
      let copyArr = JSON.stringify(item)
      copyArr = JSON.parse(copyArr)
      this.selectedField = copyArr
      this.modal = !this.modal
    },
  },
}
</script>

<style lang="scss" scoped>
.addButton {
  border: 1px solid #d5dbf1;
  border-radius: 12px;
  height: 54px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 16px;
  color: #413dff;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
  svg {
    margin-right: 8px;
  }
}
.telegram {
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  &__settings {
    margin-left: 12px;
    cursor: pointer;
  }
  &__del {
    margin-left: 12px;
    cursor: pointer;
  }
}
.sectionTelegram__mobile-info {
  display: none;
  position: static;
  width: 100%;
  @media (max-width: 1200px) {
    display: block !important;
  }
}
.sectionTelegram {
  &:deep {
    .vLandingInput {
      margin-bottom: 0;
    }
    .section-profile__legend {
      @media (max-width: 1200px) {
        display: none;
      }
    }
  }
  .err ~ * {
    margin-bottom: 16px;
  }
}
.sectionTelegram-modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  &__overlay {
    background: #dcdee3;
    opacity: 0.9;
    backdrop-filter: blur(26.5px);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
  }
  &__content {
    padding: 20px 16px;
    background: #ffffff;
    border-radius: 12px;
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 666px;
    @media (max-width: 700px) {
      width: calc(100% - 60px);
    }
  }
  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #010d35;
    text-align: center;
    margin-bottom: 24px;
    padding: 0 48px;
  }
  &__subtitle {
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
  }
  &__checkboxItem {
    background: #f7f8fa;
    border-radius: 7px;
    display: flex;
    align-items: center;
    height: 40px;
    align-items: center;
    padding: 10px;
    font-weight: 500;
    font-size: 14px;
    &.active {
      background: #f8f9fe;
      .sectionTelegram-modal__checkbox {
        background-image: url(~@/assets/images/checkbox-new.svg);
        border-color: #6764ff;
        background-position: center;
      }
    }
    cursor: pointer;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
  &__checkbox {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border: 1.5px solid #dcdee3;
    background: #fff;
    border-radius: 6px;
  }
  &__description {
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    color: #010d35;
    margin-bottom: 24px;
    text-align: center;
  }
  &__close {
    position: absolute;
    right: 20px;
    top: 22px;
    cursor: pointer;
  }
  &__buttons {
    display: flex;
    & > *:first-child {
      margin-right: 4px;
    }
    & > *:last-child {
      margin-left: 4px;
    }
  }
}
</style>
